// Licensed to the Software Freedom Conservancy (SFC) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The SFC licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

import React from 'react'
import { Box, Container, Typography } from '@mui/material'

function Error (props) {
  const { message, errorMessage } = props
  return (
    <Box
      height="100%"
      width="100%"
      paddingY={3}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Box mb={3}>
            <Typography
              align="center"
              color="textPrimary"
              variant="h3"
            >
              {message}
            </Typography>
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            variant="h4"
            component="span"
          >
            <pre>
              {errorMessage}
            </pre>
          </Typography>
        </Container>
      </Box>
    </Box>
  )
}

export default Error
